import React from 'react'
import PropTypes from 'prop-types'

import SvgContainer from './SvgContainer'

const SpeedometerSharp = props => {
  return (
    <SvgContainer
      height={props.height}
      width={props.width}
      color={props.color}
      onClick={props.onClick}
      rotate={props.rotate ? 1 : 0}
      shake={props.shake ? 1 : 0}
      beat={props.beat ? 1 : 0}
      className={props.className}
    >
      <svg style={props.style} className={props.cssClasses} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512">{props.title ? <title>{props.title}</title> : ''}<path d="M256 48C123.46 48 16 156.55 16 290.56a243.3 243.3 0 0060.32 160.87c1.18 1.3 2.25 2.6 3.43 3.79C89.2 464 92.07 464 99.57 464s12.43 0 19.93-8.88C152 416.64 202 400 256 400s104.07 16.71 136.5 55.12C400 464 404.82 464 412.43 464s11.3 0 19.82-8.78c1.22-1.25 2.25-2.49 3.43-3.79A243.3 243.3 0 00496 290.56C496 156.55 388.54 48 256 48zm-16 64h32v64h-32zm-96 192H80v-32h64zm21.49-83.88l-45.25-45.26 22.62-22.62 45.26 45.25zM278.6 307.4a31 31 0 01-7 7 30.11 30.11 0 01-35-49L320 224zm45.28-109.91l45.26-45.25 22.62 22.62-45.25 45.26zM432 304h-64v-32h64z"/></svg>
    </SvgContainer>
  )
}


SpeedometerSharp.defaultProps = {
  // style
  style: {},
  color: '#000000',
  height: '22px',
  width: '22px',
  cssClasses: '',
  title: '',

  // animation
  shake: false,
  beat: false,
  rotate: false,
}


SpeedometerSharp.propTypes = {
  // style
  style: PropTypes.object,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  cssClasses: PropTypes.string,
  title: PropTypes.string,

  // animation
  shake: PropTypes.bool,
  beat: PropTypes.bool,
  rotate: PropTypes.bool,

  // functions
  onClick: PropTypes.func
}


export default SpeedometerSharp