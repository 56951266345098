import React from 'react'
import PropTypes from 'prop-types'

import SvgContainer from './SvgContainer'

const LogoNoSmoking = props => {
  return (
    <SvgContainer
      height={props.height}
      width={props.width}
      color={props.color}
      onClick={props.onClick}
      rotate={props.rotate ? 1 : 0}
      shake={props.shake ? 1 : 0}
      beat={props.beat ? 1 : 0}
      className={props.className}
    >
      <svg style={props.style} className={props.cssClasses} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512">{props.title ? <title>{props.title}</title> : ''}<path d="M360 256h16v48h-16zM112 304h129.6l-48-48H112v48z"/><path d="M364.5 60.1a8.79 8.79 0 01-1-.6 218.79 218.79 0 00-34.4-14.8l-5.4-1.8A223.2 223.2 0 00256 32C132.3 32 32 132.3 32 256a223.71 223.71 0 00115.4 195.8c.4.2.7.5 1.1.7a218.79 218.79 0 0034.4 14.8l5.4 1.8A222.7 222.7 0 00256 480c123.7 0 224-100.3 224-224A223.76 223.76 0 00364.5 60.1zM256 426.4a161.85 161.85 0 01-27.2-2.4 170.14 170.14 0 01-28.5-7.3c-1.9-.6-3.8-1.2-5.6-1.9a162.39 162.39 0 01-19-8.6 170.33 170.33 0 01-90.1-150.3c0-37.2 12.4-71.4 32.7-99.4l237.2 237.2c-28.1 20.3-62.3 32.7-99.5 32.7zm137.8-71L156.6 118.2c28-20.2 62.1-32.6 99.4-32.6a162.79 162.79 0 0127.2 2.4 170.14 170.14 0 0128.5 7.3c1.8.6 3.7 1.2 5.6 1.9a162 162 0 0118 8.1 170.25 170.25 0 0191.2 150.8c-.1 37.2-12.5 71.3-32.7 99.3z"/><path d="M352 256h-34l34 34v-34zM384 256h16v48h-16zM360.1 212.7c-8.8-4.1-22-5.7-45.6-5.7h-3.6c-12.7.1-15.9-.1-20-6.1-2.8-4.2-1-14.8 3.7-21.9a8 8 0 00.4-8.2 8.26 8.26 0 00-7-4.3 53.67 53.67 0 01-18.3-3.9c-10.6-4.5-15.6-12.1-15.6-23.1 0-25.8 21.8-27.7 22.8-27.7v-16c-12 0-38.8 11-38.8 43.7 0 17.5 9 31 25.7 38a66.58 66.58 0 0012 3.6c-3.3 9.8-3.6 20.9 1.7 28.7 9 13.3 20.3 13.2 33.3 13.1h3.5c26.3 0 34.6 2.3 38.9 4.3 5.7 2.6 6.8 7.5 6.6 15.7v1h16v-1c0-7.1.3-22.8-15.7-30.2z"/><path d="M400 244c0-25.7-3-39.2-9.1-49.6C382.3 180 368.5 172 352 172h-17.4c2.9-8.3 5.4-19.8 3.5-30.9-3.2-18.8-19.1-30-43.1-30v16c21 0 26.1 9.1 27.4 16.7 2.5 14.5-6.8 32.1-6.9 32.3a8 8 0 00.1 7.9 8.06 8.06 0 006.9 3.9H352c10.9 0 19.4 4.9 25.1 14.6 3.1 5.3 6.9 13.5 6.9 41.4h16z"/></svg>
    </SvgContainer>
  )
}


LogoNoSmoking.defaultProps = {
  // style
  style: {},
  color: '#000000',
  height: '22px',
  width: '22px',
  cssClasses: '',
  title: '',

  // animation
  shake: false,
  beat: false,
  rotate: false,
}


LogoNoSmoking.propTypes = {
  // style
  style: PropTypes.object,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  cssClasses: PropTypes.string,
  title: PropTypes.string,

  // animation
  shake: PropTypes.bool,
  beat: PropTypes.bool,
  rotate: PropTypes.bool,

  // functions
  onClick: PropTypes.func
}


export default LogoNoSmoking