import React from 'react'
import PropTypes from 'prop-types'

import SvgContainer from './SvgContainer'

const TextSharp = props => {
  return (
    <SvgContainer
      height={props.height}
      width={props.width}
      color={props.color}
      onClick={props.onClick}
      rotate={props.rotate ? 1 : 0}
      shake={props.shake ? 1 : 0}
      beat={props.beat ? 1 : 0}
      className={props.className}
    >
      <svg style={props.style} className={props.cssClasses} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512">{props.title ? <title>{props.title}</title> : ''}<path d="M404.42 170c-41.23 0-78.07 24.06-93.85 61.3L304 246.52l40.33 17.18 6.56-15.22c8.9-21 29.91-34.55 53.53-34.55 34.55 0 57.76 23.27 57.76 57.91v2.3c-22.12.59-48.65 2.05-72.27 4.84-54.52 6.43-87.06 36.23-87.06 79.72 0 23.16 8.72 44 24.56 58.59C342.28 431 362.55 438 384.51 438c30.86 0 57.5-7.33 77.67-22.64V438H506V271.84C506 212.83 463.28 170 404.42 170zm-19.91 225.07c-17.46 0-37.85-9.84-37.85-36.37 0-10.65 3.82-18.11 12.38-24.19 8.34-5.92 21.12-10.15 36-11.9 21.78-2.57 46.31-3.95 67-4.52-2.16 51.49-27.57 76.98-77.53 76.98zM93.25 325.87h125.5L260.94 438H308L155 48 4 438h47.06zM156 160.71L202.25 282h-92.5z"/></svg>
    </SvgContainer>
  )
}


TextSharp.defaultProps = {
  // style
  style: {},
  color: '#000000',
  height: '22px',
  width: '22px',
  cssClasses: '',
  title: '',

  // animation
  shake: false,
  beat: false,
  rotate: false,
}


TextSharp.propTypes = {
  // style
  style: PropTypes.object,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  cssClasses: PropTypes.string,
  title: PropTypes.string,

  // animation
  shake: PropTypes.bool,
  beat: PropTypes.bool,
  rotate: PropTypes.bool,

  // functions
  onClick: PropTypes.func
}


export default TextSharp